/* ==========================================================================
    Typonomy
   ========================================================================== */

   @font-face {
    font-family: 'Futura';
    src: url('../assets/fonts/futura_bold.eot'); /* IE9 Compat Modes */
    src: url('../assets/fonts/futura_bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/futura_bold.woff') format('woff'), url('../assets/fonts/futura_bold.ttf') format('truetype'), url('../assets/fonts/futura_bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

/* cyrillic-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/ek4gzZ-GeXAPcSbHtCeQI_esZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0460 - 052F, U+20B4, U+2DE0 - 2DFF, U+A640-A69F;
}
/* cyrillic */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/mErvLBYg_cXG3rLvUsKT_fesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0400 - 045F, U+0490 - 0491, U+04B0 - 04B1, U+2116;
}
/* greek-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/-2n2p-_Y08sg57CNWQfKNvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+1F00 - 1FFF;
}
/* greek */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/u0TOpm082MNkS5K0Q4rhqvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0370 - 03FF;
}
/* vietnamese */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/NdF9MtnOpLzo-noMoG0miPesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0102 - 0103, U+1EA0 - 1EF9, U+20AB;
}
/* latin-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
    unicode-range: U+0100 - 024F, U+1E00 - 1EFF, U+20A0 - 20AB, U+20AD-20CF, U+2C60 - 2C7F, U+A720-A7FF;
}
/* latin */
   @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/CWB0XYA8bzo0kSThX0UTuA.woff2) format('woff2');
    unicode-range: U+0000 - 00FF, U+0131, U+0152 - 0153, U+02C6, U+02DA, U+02DC, U+2000 - 206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* cyrillic-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/77FXFjRbGzN4aCrSFhlh3hJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0460 - 052F, U+20B4, U+2DE0 - 2DFF, U+A640-A69F;
}
/* cyrillic */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/isZ-wbCXNKAbnjo6_TwHThJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0400 - 045F, U+0490 - 0491, U+04B0 - 04B1, U+2116;
}
/* greek-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/UX6i4JxQDm3fVTc1CPuwqhJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+1F00 - 1FFF;
}
/* greek */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/jSN2CGVDbcVyCnfJfjSdfBJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0370 - 03FF;
}
/* vietnamese */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/PwZc-YbIL414wB9rB1IAPRJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0102 - 0103, U+1EA0 - 1EF9, U+20AB;
}
/* latin-ext */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0100 - 024F, U+1E00 - 1EFF, U+20A0 - 20AB, U+20AD-20CF, U+2C60 - 2C7F, U+A720-A7FF;
}
/* latin */
   @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-style: normal;
    src: url(https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2) format('woff2');
    unicode-range: U+0000 - 00FF, U+0131, U+0152 - 0153, U+02C6, U+02DA, U+02DC, U+2000 - 206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

#body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.58;
    color: $text-color;
    //letter-spacing: 0.06rem;
    // letter-spacing: 0.01rem;
    letter-spacing: -0.003em;
    font-feature-settings: 'hist' 1, 'liga' 1, 'onum' 1, 'frac' 1, 'calt' 1, 'dlig' 1, 'swsh' 1;
}

.serif,
p {
    margin: 0 0 1.75em;

    font-family: Georgia, Cambria,'Times New Roman',Times,serif;
    font-size: 1em;
    letter-spacing: 0.025em;

    @include responsiveNum('line-height', 1.5, 1.75);
}
