/* ==========================================================================
    Content
   ========================================================================== */

.mp-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 14px 28px rgba(0,0,0,0.35), 0 10px 10px rgba(0,0,0,0.32);
	}

.mp-wrap {
    display: flex;
	}

.is-post.mp-content {
    position: relative;
    min-height: calc(100vh - 130px);
	}

@media (max-width: #{$break}) {
    .mp-content,
    .is-post.mp-content {
        position: relative;
        min-height: 100vh;
	    }
	}

@media (min-width: #{$break}) {
    .mp-wrap {
        height: 100%;
	    }
	}

.mp-pane {
    flex: 0 0 50%;
	}
