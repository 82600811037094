/* ==========================================================================
    Share
   ========================================================================== */

.mp-share-title {
    font-family: 'Futura', sans-serif;
    font-size: 12px;
    padding-bottom: 1em;
    text-align: center;
    text-transform: uppercase;
	}

.mp-share-links {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;

    justify-content: space-around;
    align-items: center;
	}

.mp-social svg,
.mp-share-link svg {
    width: 24px;
    height: 24px;
    transition: opacity .3s ease-out;
    opacity: .5;

    fill: $text-color;
	}

.mp-social:hover svg,
.mp-share-link:hover svg {
    opacity: 1;
	}
