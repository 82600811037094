// ==========================================================================
//    Variables
//   ========================================================================= / /


$body-color: #1e282d;
$text-color: #455a64;
$nav-color: #e0e0e0;
$logo-color: #eb5757;

$easer: cubic-bezier(.465, .2, .03, 1);

$mobile: 705px;
$break: 1000px;
$col: 600px;

$z-logo: 200;
