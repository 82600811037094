/* ==========================================================================
    Perfect Scrollbar
   ========================================================================== */

/* perfect-scrollbar v0.6.12 */
.ps-container {
    overflow: hidden !important;

    // -ms-touch-action: none;
    //     touch-action: none;
    -ms-overflow-style: none;
	}
@supports (-ms-overflow-style: none) {
    .ps-container {
        overflow: auto !important;
	    }
	}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-container {
        overflow: auto !important;
	    }
	}
.ps-container.ps-active-x > .ps-scrollbar-x-rail,
.ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent;
	}
.ps-container.ps-in-scrolling {
    pointer-events: none;
	}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    opacity: .9;
    background-color: #eee;
	}
.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: #999;
	}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    opacity: .9;
    background-color: #eee;
	}
.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: #999;
	}
.ps-container > .ps-scrollbar-x-rail {
    position: absolute;
    bottom: 0;
    display: none;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 15px;
    transition: background-color .2s linear, opacity .2s linear;
    /* please don't change 'position' */
    opacity: 0;
	}
.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    position: absolute;
    bottom: 2px;
    /* there must be 'bottom' for ps-scrollbar-x */
    height: 6px;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    border-radius: 6px;
    /* please don't change 'position' */
    background-color: #aaa;
	}
.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x,
.ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
    height: 11px;
	}
.ps-container > .ps-scrollbar-y-rail {
    position: absolute;
    right: 0;
    display: none;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 15px;
    transition: background-color .2s linear, opacity .2s linear;
    /* please don't change 'position' */
    opacity: 0;
	}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    position: absolute;
    right: 2px;
    /* there must be 'right' for ps-scrollbar-y */
    width: 6px;
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    border-radius: 6px;
    /* please don't change 'position' */
    background-color: #aaa;
	}
.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
    width: 11px;
	}
.ps-container:hover.ps-in-scrolling {
    pointer-events: none;
	}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    opacity: .9;
    background-color: #eee;
	}
.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: #999;
	}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    opacity: .9;
    background-color: #eee;
	}
.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: #999;
	}
.ps-container:hover > .ps-scrollbar-x-rail,
.ps-container:hover > .ps-scrollbar-y-rail {
    opacity: .6;
	}
.ps-container:hover > .ps-scrollbar-x-rail:hover {
    opacity: .9;
    background-color: #eee;
	}
.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
    background-color: #999;
	}
.ps-container:hover > .ps-scrollbar-y-rail:hover {
    opacity: .9;
    background-color: #eee;
	}
.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
    background-color: #999;
	}
