/* ==========================================================================
    Scene transitions
   ========================================================================== */


.mp-scene {
    .mp-element,
    .mp-elem {
        animation-duration: .5s;
        animation-timing-function: ease-out;

        animation-fill-mode: both;
	    }

    .mp-scale {
        animation-name: scale;

        will-change: transform, opacity;
	    }

    .mp-iris {
        animation-name: iris;

        will-change: clip-path;
    }

    .mp-iris.is-exiting {
        animation-delay: .5s;
    }

    .mp-fade,
    .mp-enter-fade {
        animation-name: fade;

        will-change: opacity;
	    }

    .mp-one.mp-scale {
        animation-name: slideLeft;
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
	    }

    .mp-two.mp-scale {
        animation-name: slideRight;
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
	    }

    .mp-slideUp,
    &.is-exiting .mp-exit-slideUp {
        animation-name: slideUp;
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
	    }

    .mp-slideInRight {
        animation-name: slideInRight;
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
	    }

    .mp-enter-fade {
        animation-name: fade;

        will-change: opacity;
	    }

    .mp-slideDown {
        animation-name: slideDown;

        will-change: transform, opacity;
	    }

    .mp-two .mp-element {
        animation-delay: .4s;
	    }

    &.is-exiting .mp-element,
    &.is-exiting .mp-exit-slideUp {
        animation-direction: alternate-reverse;
	    }

    .mp-two .mp-delay-1 {
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
        animation-delay: .1s;
	    }

    .mp-two .mp-delay-2 {
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
        animation-delay: .3s;
	    }

    .mp-two .mp-delay-3 {
        animation-timing-function: cubic-bezier(.68, -.25, .265, 1.25);
        animation-delay: .5s;
	    }
	}


@keyframes scale {
    0% {
        transform: translate3d(100%, 0, 0);
	    }
    100% {
        transform: none;
        }
	}


@keyframes fade {
    0% {
        opacity: 0;
	    }
    100% {
        opacity: 1;
	    }
	}

@keyframes slideUp {
    0% {
        transform: translate3d(0, -100%, 0);
	    }
    100% {
        transform: none;
	    }
	}

@keyframes slideDown {
    0% {
        transform: translate3d(0, 10%, 0);
        opacity: 0;
	    }
    100% {
        transform: none;
        opacity: 1;
	    }
	}

@keyframes slideLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
	    }
    100% {
        transform: none;
	    }
	}

@keyframes slideRight {
    0% {
        transform: translate3d(100%, 0, 0);
	    }
    100% {
        transform: none;
	    }
	}

@keyframes slideInRight {
    0% {
        transform: translate3d(130%, 0, 0);
        opacity: 0;
	    }
    100% {
        transform: none;
        opacity: 1;
	    }
	}

@keyframes iris {
    0% {
        clip-path: circle(0% at 50% 50%);
    }
    100% {
        clip-path: circle(100% at 50% 50%);
    }
}
